export const environment = {
  production: true,
  version: '0.1.21.24918.1029',
  ssrUrl: 'https://fleebs.com',
  prerenderUrl: 'http://localhost:8080',
  apiUrl:  'https://api.fleebs.com',
  socketServer: 'https://socket.fleebs.com',
  imageServer: 'https://image.fleebs.com',
  mapServer: 'https://map.fleebs.com',
  accountServer: 'https://auth.fleebs.com',
  trackingUrl: 'https://tracking.fleebs.com',
  trackingSiteId: '1',
  isCookieConsentEnabled: true,
  isUserAccountEnabled: true,
};
